import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import Icon from '@/components/atoms/icon';
import { withTransientProps } from '@/helpers/with-transient-props';
import StandardLink from '@/components/atoms/standard-link';

type StyledTypographyProps = {
  type: string;
  $darkMode?: boolean;
};

type SecondaryTypographyProps = {
  $darkMode?: boolean;
};

type StyledButtonProps = {
  $darkMode?: boolean;
};

type StyledLinkProps = {
  $darkMode?: boolean;
};

export const StyledTypography = styled(
  Typography,
  withTransientProps,
)<StyledTypographyProps>(({ theme, type, $darkMode }) => ({
  color: $darkMode
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
  ...(['button', 'link'].includes(type) && {
    borderBottom: `${theme.spacing(0.2)} solid ${
      $darkMode
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary
    }`,
    padding: theme.spacing(0),
    '&:hover': {
      borderBottom: `${theme.spacing(0.2)} solid ${theme.palette.primary.main}`,
    },
  }),
}));

export const StyledButton = styled(
  Button,
  withTransientProps,
)<StyledButtonProps>(({ theme, $darkMode }) => ({
  fontSize: theme.spacing(1.6),
  padding: theme.spacing(0),
  textTransform: 'inherit',
  textAlign: 'left',
  fontWeight: 400,
  lineHeight: theme.spacing(2.4),
  color: theme.palette.text.primary,
  '&:hover': {
    background: 'none',
    border: 'none',
    outline: 'none',
  },
  '&:focus': {
    outline: $darkMode ? `${theme.spacing(0.1)} solid ${theme.palette.primary.contrastText}`
      : `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
    outlineOffset: theme.spacing(0.3),
    borderRadius: 0,
  },
  minWidth: 0,
}));

export const SecondaryTypography = styled(
  Typography,
  withTransientProps,
)<SecondaryTypographyProps>(({ theme, $darkMode }) => ({
  color: $darkMode
    ? theme.palette.primary.contrastText
    : theme.palette.primary.main,
  marginLeft: theme.spacing(1),
}));

export const StyledIcon = styled(Icon)(({ theme, icon }) => ({
  fill: theme.palette.primary.main,
  ...(icon === 'icon-chat-mc' && {
    fill: theme.palette.text.primary,
    marginLeft: theme.spacing(-0.3),
    marginRight: theme.spacing(1.1),
    height: theme.spacing(2.6),
    width: theme.spacing(3.9),
    overflow: 'visible',
    '&.small': {
      height: theme.spacing(2.4),
      width: theme.spacing(3.6),
    },
  }),
  ...(icon === 'icon-text-sms-mc' && {
    marginRight: theme.spacing(1.6),
    height: theme.spacing(3.9),
    width: theme.spacing(3.1),
    overflow: 'visible',
    '&.small': {
      height: theme.spacing(2.4),
      width: theme.spacing(1.9),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.9),
    },
  }),
  ...(icon === 'icon-support-mc' && {
    marginLeft: theme.spacing(-0.7),
    marginRight: theme.spacing(0.5),
    height: theme.spacing(4.8),
    width: theme.spacing(4.8),
    '&.small': {
      height: theme.spacing(2.8),
      width: theme.spacing(2.4),
      marginLeft: theme.spacing(0.1),
      marginRight: theme.spacing(0.9),
    },
  }),
  ...(icon === 'icon-email-mc' && {
    marginRight: theme.spacing(1.3),
    marginTop: theme.spacing(0.5),
    height: theme.spacing(1.8),
    width: theme.spacing(3.5),
    overflow: 'visible',
    '&.small': {
      height: theme.spacing(1.5),
      width: theme.spacing(2.9),
      marginRight: theme.spacing(0.4),
    },
  }),
  ...(icon === 'icon-instructor-mc' && {
    marginRight: theme.spacing(0.7),
    height: theme.spacing(3.5),
    width: theme.spacing(3.5),
    overflow: 'visible',
    '&.small': {
      height: theme.spacing(2.4),
      width: theme.spacing(2.4),
      marginRight: theme.spacing(0.9),
    },
  }),
  ...(icon === 'icon-alert-mc' && {
    marginRight: theme.spacing(1.3),
    width: theme.spacing(2),
    height: theme.spacing(1.8),
  }),
  ...(icon === 'icon-calendar-mc' && {
    marginRight: theme.spacing(1.3),
    width: theme.spacing(2),
    height: theme.spacing(1.8),
  }),
  ...(icon === 'icon-contact' && {
    fill: theme.palette.text.primary,
    marginRight: theme.spacing(0.9),
  }),
}));

export const HoverLink = styled(
  StandardLink,
  withTransientProps,
)<StyledLinkProps>(({ theme, $darkMode }) => ({
  fontSize: theme.spacing(1.6),
  '&:hover .svg': {
    transform: `translate(${theme.spacing(0.4)}, 0)`,
    transition: 'transform 0.2s linear',
  },
  '&:focus': {
    outline: $darkMode ? `${theme.spacing(0.1)} solid ${theme.palette.primary.contrastText}`
      : `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
    outlineOffset: theme.spacing(0.3),
  },
}));
