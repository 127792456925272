import { styled } from '@mui/material/styles';
import {
  CircularProgress,
  Card as MuiCard,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
  Typography,
  List,
  Box,
} from '@mui/material';
import StandardButton from '@/components/atoms/standard-button';
import Icon from '@/components/atoms/icon';
import { withTransientProps } from '@/helpers/with-transient-props';
import StandardLink from '@/components/atoms/standard-link';

export const Card = styled(MuiCard, withTransientProps)(({ theme, $darkMode }) => ({
  ...($darkMode && {
    backgroundColor: theme.palette.almostBlack.main,
    color: theme.palette.primary.contrastText,
    '& p.MuiTypography-root': {
      color: theme.palette.primary.contrastText,
    },
  }),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  margin: '0 auto',
  padding: theme.spacing(2),
  '& .MuiCardActions-root': {
    padding: theme.spacing(0),
  },
}));
export const SpinnerWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
}));
export const Loading = styled(CircularProgress)(({ theme }) => ({
  minWidth: theme.spacing(15),
  minHeight: theme.spacing(15),
  alignSelf: 'center',
}));
export const Label = styled(Typography, withTransientProps)(({ theme, $darkMode }) => ({
  color: $darkMode ? theme.palette.primary.contrastText : theme.palette.text.disabled,
  fontWeight: 700,
  textTransform: 'uppercase',
  lineHeight: theme.spacing(1.6),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    paddingBottom: theme.spacing(1.5),
  },
}));
export const CardContent = styled(MuiCardContent)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    marginRight: 0,
  },
}));
export const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    flexDirection: 'row',
  },
}));
export const StyledIcon = styled(Icon)(({ theme }) => ({
  height: theme.spacing(4.1),
  width: theme.spacing(4.1),
  overflow: 'visible',
  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(1.5),
  },
}));
export const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  lineHeight: theme.spacing(2.8),
  marginTop: theme.spacing(0.6),
}));
export const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(3.2),
  marginLeft: theme.spacing(2),
  maxWidth: theme.spacing(62.5),
  '& div:last-child,p:last-child': {
    marginBottom: 0,
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(5.5),
  },
}));
export const SubLabel = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  fontWeight: '600',
}));
export const FootNotes = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  fontWeight: 'bold',
  color: theme.palette.text.hint,
  flex: 1,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3.5),
  },
}));
export const MainContent = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  color: theme.palette.text.primary,
}));
export const ContentSpacing = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3.2),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(4),
  },
}));
export const ListContent = styled(List)(({ theme }) => ({
  fontWeight: '400',
  fontSize: theme.spacing(1.6),
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3.2),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(4),
  },
}));
export const AdditionalContentSpacing = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3.2),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(4),
  },
}));
export const Phone = styled(StandardLink, withTransientProps)(({ theme, $darkMode }) => ({
  fontSize: theme.spacing(1.6),
  padding: theme.spacing(0),
  color: $darkMode ? theme.palette.primary.contrastText : theme.palette.text.primary,
  borderBottom: $darkMode ? `${theme.spacing(0.2)} solid ${theme.palette.primary.contrastText}`
    : `${theme.spacing(0.2)} solid ${theme.palette.text.primary}`,
  wordBreak: 'break-all',
  lineHeight: theme.spacing(2.4),
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
    borderBottom: `${theme.spacing(0.2)} solid ${theme.palette.primary.main}`,
  },
  '&:focus': {
    textDecoration: 'none',
    outlineStyle: 'solid',
    outlineOffset: theme.spacing(0.3),
    outlineColor: $darkMode ? theme.palette.primary.contrastText : theme.palette.text.primary,
  },
}));
export const PrimaryButton = styled(StandardButton)(({ theme }) => ({
  borderRadius: theme.spacing(2.8),
  height: theme.spacing(4),
  width: '100%',
  textTransform: 'none',
  boxShadow: 'none',
  marginBottom: theme.spacing(2.4),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.dark,
    outline: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: theme.spacing(24),
    height: theme.spacing(5.6),
  },
}));
export const SecondaryButton = styled(StandardButton)(({ theme }) => ({
  borderRadius: theme.spacing(2.8),
  height: theme.spacing(4),
  width: '100%',
  textTransform: 'none',
  boxShadow: 'none',
  marginBottom: theme.spacing(2.4),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  border: `${theme.spacing(0.2)} solid ${theme.palette.primary.main}`,
  marginLeft: 0,
  '&:hover': {
    outline: 'none',
    boxShadow: 'none',
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.common.white,
    border: `${theme.spacing(0.2)} solid ${theme.palette.primary.dark}`,
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: theme.spacing(24),
    height: theme.spacing(5.6),
  },
  [theme.breakpoints.down('sm')]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      outline: 'none',
      color: theme.palette.common.white,
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));
export const SecondarySmallButton = styled(StandardButton)(({ theme }) => ({
  borderRadius: theme.spacing(2.8),
  height: theme.spacing(4),
  width: '100%',
  textTransform: 'none',
  boxShadow: 'none',
  marginBottom: theme.spacing(2.4),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  border: `${theme.spacing(0.2)} solid ${theme.palette.primary.main}`,
  marginLeft: 0,
  '&:hover': {
    outline: 'none',
    boxShadow: 'none',
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.common.white,
    border: `${theme.spacing(0.2)} solid ${theme.palette.primary.dark}`,
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: theme.spacing(24),
    height: theme.spacing(5.6),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      outline: 'none',
      color: theme.palette.common.white,
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));
export const ButtonText = styled(Typography)(() => ({
  fontWeight: '500',
}));
export const StyledLink = styled('span')(({ theme, $darkMode }) => ({
  cursor: 'pointer',
  fontSize: theme.spacing(1.5),
  color: $darkMode ? theme.palette.primary.contrastText : theme.palette.text.primary,
  borderBottom: $darkMode ? `${theme.spacing(0.3)} solid ${theme.palette.primary.contrastText}`
    : `${theme.spacing(0.3)} solid ${theme.palette.common.black}`,
  marginBottom: theme.spacing(2),
  '&:hover': {
    textDecoration: 'none',
    borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.primary.main}`,
  },
}));
export const CardActions = styled(MuiCardActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacing(6, 1, 0, 1),
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
}));
export const CardActionsSmall = styled(MuiCardActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: theme.spacing(6, 1, 0, 1),
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    left: theme.spacing(8),
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(6, 1, 0, 1),
  },
}));
export const DateRange = styled(Typography)(({ theme }) => ({
  margin: `${theme.spacing(1)} ${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(0)}`,
}));
export const AssignedContactsHeading = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  lineHeight: theme.spacing(2.8),
  marginTop: theme.spacing(0.6),
}));
