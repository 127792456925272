/**
 * Formats phone number to look like: XXX-XXX-XXXX
 * Returns the original phone number as a fallback in case of failure.
 * @param phoneNumberString
 * @returns {string|*}
 */
export default function formatPhoneNumberWithoutParentheses(phoneNumberString) {
  // removes leading +1 along with any non-digit chars
  const scrubbed = phoneNumberString.replace(/^(\+1)|\D/g, '');
  const numberGroups = scrubbed.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (numberGroups) {
    return `${numberGroups[1]}-${numberGroups[2]}-${numberGroups[3]}`;
  }
  return phoneNumberString;
}

/**
 * Formats phone number to look like: (XXX) XXX-XXXX
 * Returns the original phone number as a fallback in case of failure.
 * @param phoneNumberString
 * @returns {string|*}
 */
export function formatPhoneNumberWithParentheses(phoneNumberString) {
  if ((phoneNumberString || '') === '') return '';
  // removes leading +1 along with any non-digit chars
  const scrubbed = phoneNumberString.replace(/^(\+1)|\D/g, '');
  const numberGroups = scrubbed.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (numberGroups) {
    return `(${numberGroups[1]}) ${numberGroups[2]}-${numberGroups[3]}`;
  }
  return phoneNumberString;
}

export function callPhoneNumber(phoneNumber) {
  window.location.href = `tel:${phoneNumber}`;
}
