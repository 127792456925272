import { useGetMembershipsBySourceIdsTypeAndRoleQuery } from '@/store/queries/v2/courses';
import { useGetNamesByPersonIdsQuery } from '@/store/queries/v2/person';
import { useGetEmailsByPersonIdsQuery } from '@/store/queries/v2/contacts';
import { useMemo } from 'react';
import { createUniversityContactObject } from '@/store/helpers/university-contacts';
import { Instructor } from '@/types/courseInfoData';
import { cleanArrayOfObjects } from '@/helpers/object';

interface UseGetInstructorsBySourceIdsProps {
  sourceIds: string [];
  sourceType?: string;
  roleType?: string;
  skip?: boolean;
}

const useGetInstructorsBySourceIdsTypeAndRole = ({
  sourceIds,
  sourceType = 'SECTION',
  roleType = 'IN',
  skip = false,
}: UseGetInstructorsBySourceIdsProps) => {
  const {
    data: membershipsBySourceIdsTypeAndRole = [],
    isFetching: isGetMembershipsBySourceIdsTypeAndRoleFetching,
    isError: isGetMembershipsBySourceIdsTypeAndRoleError,
  } = useGetMembershipsBySourceIdsTypeAndRoleQuery(
    { sourceIds, sourceType, roleType },
    { skip: skip || !sourceIds },
  );

  const personIds = membershipsBySourceIdsTypeAndRole.map((membership) => membership.personId);
  const {
    data: personNamesObjects = [],
    isFetching: isPersonNamesByPersonIdsFetching,
    isError: isPersonNamesByPersonIdsError,
  } = useGetNamesByPersonIdsQuery(
    { personIds },
    { skip: skip || !personIds || personIds.length < 1 },
  );

  const {
    data: emailsObjects = [],
    isFetching: isEmailsByPersonIdsFetching,
    isError: isEmailsByPersonIdsError,
  } = useGetEmailsByPersonIdsQuery(
    { personIds },
    { skip: skip || !personIds || personIds.length < 1 },
  );

  const primaryInstructorMemberships = membershipsBySourceIdsTypeAndRole.filter(
    (membership) => membership?.roleType?.toLowerCase() === 'instructor' && membership?.subRoleType?.toLowerCase() === 'primary',
  );

  const isFetching = isGetMembershipsBySourceIdsTypeAndRoleFetching
  || isPersonNamesByPersonIdsFetching || isEmailsByPersonIdsFetching;

  const isError = isGetMembershipsBySourceIdsTypeAndRoleError
  || isPersonNamesByPersonIdsError || isEmailsByPersonIdsError;

  const data = useMemo(() => {
    if (skip || isFetching || isError) {
      return [];
    }
    return cleanArrayOfObjects(
      primaryInstructorMemberships.map(
        (membership) => createUniversityContactObject(
          membership,
          emailsObjects,
          personNamesObjects,
        ),
      ),
    );
  }, [
    isFetching,
    isError,
    skip,
    primaryInstructorMemberships,
    emailsObjects,
    personNamesObjects,
  ]);

  return {
    isFetching,
    isError,
    data: data as Instructor [],
  };
};

export default useGetInstructorsBySourceIdsTypeAndRole;
