import { useMemo } from 'react';
import { useGetAllProgramsByPersonIdQuery } from '@/store/queries/v2/student';
import { useGetSupportContactsByPersonIdAndProgramCodeQuery } from '@/store/queries/v2/contacts';

export type ProgramContactType = {
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
  email?: string,
  location?: string,
  programCode?: string,
};

export const useGetPrimaryProgramContactsByPersonIdHook = (
  personId: string,
  skip: boolean = false,
) => {
  const {
    data: allPrograms = [],
    isFetching: isGetProgramsByIdFetching,
    isError: isGetProgramsByIdError,
  } = useGetAllProgramsByPersonIdQuery({ personId }, { skip: skip || !personId });

  const programCode = useMemo(
    () => (allPrograms.find(
      (program) => program.primary === 'true',
    )?.externalSystemIds?.programCodeVersion || [''])[0].split(':')[0],
    [allPrograms],
  );

  const {
    data: supportContacts,
    isFetching: isGetSupportContactsFetching,
    isError: isGetSupportContactsError,
  } = useGetSupportContactsByPersonIdAndProgramCodeQuery(
    { personId, programCode },
    { skip: skip || !personId || !programCode },
  );

  const {
    academicCounselor,
    enrollmentRep,
  } = useMemo(() => {
    const ACs: ProgramContactType[] = [];
    const ERs: ProgramContactType[] = [];
    if (supportContacts?.univContacts?.length) {
      supportContacts.univContacts.forEach((univContact) => {
        univContact.support.filter((contact) => contact.contactGroup.includes('AC')).forEach((ac) => {
          if (ac.person) {
            ACs.push({
              firstName: ac.person.name?.firstName,
              lastName: ac.person.name?.lastName,
              phoneNumber: ac.person.phones?.[0]?.phoneNumber,
              email: ac.person.email?.[0]?.emailAddress,
              location: univContact.academicProgram?.offeredAtLocation?.name,
              programCode: univContact.academicProgram?.academicProgramCode,
            });
          }
        });
        univContact.support.filter((contact) => contact.contactGroup.includes('EC')).forEach((er) => {
          if (er.person) {
            ERs.push({
              firstName: er.person.name?.firstName,
              lastName: er.person.name?.lastName,
              phoneNumber: er.person.phones?.[0]?.phoneNumber,
              email: er.person.email?.[0]?.emailAddress,
              location: univContact.academicProgram?.offeredAtLocation?.name,
              programCode: univContact.academicProgram?.academicProgramCode,
            });
          }
        });
      });
    }
    return {
      academicCounselor: ACs,
      enrollmentRep: ERs,
    };
  }, [supportContacts]);
  return {
    data: {
      personId,
      enrollmentRep,
      academicCounselor,
    },
    isFetching: isGetProgramsByIdFetching || isGetSupportContactsFetching,
    isError: isGetProgramsByIdError || isGetSupportContactsError,
  };
};
