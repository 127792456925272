import { styled } from '@mui/material/styles';
import {
  MenuItem as MuiItem, Select as MuiSelect,
} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Icon from '@/components/atoms/icon';
import { withTransientProps } from '@/helpers/with-transient-props';

export const Select = styled(
  MuiSelect,
  withTransientProps,
)(({ theme, $singleOptionView = false }) => ({
  width: '100%',
  fontWeight: 'bold',
  fontSize: theme.spacing(2.4),
  '.MuiSelect-icon': {
    color: theme.palette.primary.main,
    height: theme.spacing(6),
    width: theme.spacing(5),
    top: theme.spacing(-1),
    right: theme.spacing(-1.3),
  },
  '.MuiSelect-standard': {
    color: theme.palette.text.primary,
  },
  '.MuiSelect-select:focus': {
    backgroundColor: theme.palette.common.white,
    outline: `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
    outlineOffset: theme.spacing(0.3),
  },
  '.MuiSvgIcon-root': {
    display: $singleOptionView && 'none',
  },
  '.MuiInputBase-input.Mui-disabled': {
    textFillColor: theme.palette.almostBlack.main,
  },
}));

export const CustomInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: theme.spacing(2.4),
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
}));

export const MenuItem = styled(MuiItem)(({ theme }) => ({
  backgroundColor: 'none',
  paddingLeft: theme.spacing(3.2),
  '&.MuiMenuItem-root.Mui-selected': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(1.2),
    '.checkmark': {
      display: 'initial',
      fill: theme.palette.text.primary,
      width: theme.spacing(1.2),
      height: theme.spacing(1.2),
      marginRight: theme.spacing(0.7),
    },
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.common.white,
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
  },

}));

export const SelectedIcon = styled(Icon)(() => ({
  display: 'none',
}));
