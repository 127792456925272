import { BackgroundGrid, BackgroundIcon } from './PhoenixBackground.styles';

function PhoenixBackground({ children }) {
  return (
    <BackgroundGrid
      pt={2}
      pb={4}
    >
      <BackgroundIcon icon="icon-phoenix-logo" id="background-icon-phoenix-logo" />
      {children}
    </BackgroundGrid>
  );
}

export default PhoenixBackground;
