import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Icon from '@/components/atoms/icon';

export const BackgroundIcon = styled(Icon)(({ theme }) => ({
  height: theme.spacing(37),
  width: theme.spacing(29),
  position: 'absolute',
  zIndex: '-1',
  transform: `translate(${theme.spacing(3)}, ${theme.spacing(-3.5)})`,
  fill: '#EDEDED',
  [theme.breakpoints.up('lg')]: {
    left: theme.spacing(30),
  },
  [theme.breakpoints.only('md')]: {
    left: theme.spacing(15),
  },
  [theme.breakpoints.down('sm')]: {
    transform: `translate(0, ${theme.spacing(-3.5)})`,
  },
}));

export const BackgroundGrid = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.almostBlack.l90,
  width: '100%',
  zIndex: 0,
}));
