// TODO: Add ChatLinkContainer once PHOEBE chatbot has been created (VET-29692)
import React from 'react';
import PropTypes from 'prop-types';
import IconItem from '@/components/molecules/icon-item';

export default function Contact({
  variant,
  phone = '',
  text = null,
  email = null,
  contactName = null,
  longForm = false,
  smallIcon = false,
  darkMode = false,
}) {
  switch (variant) {
    case 'phone':
      return longForm ? (
        <IconItem
          type="link"
          text={phone}
          icon={darkMode ? '' : 'icon-support-mc'}
          target="_self"
          url={`tel:${phone}`}
          secondaryText={`Call ${contactName}`}
          secondaryUrl={`tel:${phone}`}
          small={smallIcon}
          darkMode={darkMode}
          parentId="contact-phone-long"
        />
      ) : (
        <IconItem
          type="link"
          text={phone}
          icon={darkMode ? '' : 'icon-support-mc'}
          url={`tel:${phone}`}
          small={smallIcon}
          darkMode={darkMode}
          parentId="contact-phone"
        />
      );
    case 'text':
      return longForm ? (
        <IconItem
          type="link"
          text={text}
          icon={darkMode ? '' : 'icon-text-sms-mc'}
          target="_self"
          url={`sms:${text}`}
          secondaryText="Text"
          secondaryUrl={`sms:${text}`}
          small={smallIcon}
          darkMode={darkMode}
          parentId="contact-text-long"
        />
      ) : (
        <IconItem
          type="link"
          text={text}
          icon={darkMode ? '' : 'icon-text-sms-mc'}
          url={`sms:${text}`}
          small={smallIcon}
          darkMode={darkMode}
          parentId="contact-text"
        />
      );
    case 'chat':
      return (
        <IconItem
          type="link"
          text="Live Chat"
          icon={darkMode ? '' : 'icon-chat-mc'}
          small={smallIcon}
          darkMode={darkMode}
          parentId="contact-chat"
        />
      );
    case 'email':
      return longForm ? (
        <IconItem
          type="link"
          text={email}
          icon={darkMode ? '' : 'icon-email-mc'}
          url={`mailto:${email}`}
          target="_self"
          secondaryText={`Email ${contactName}`}
          secondaryUrl={`mailto:${email}`}
          small={smallIcon}
          darkMode={darkMode}
          parentId="contact-email-long"
        />
      ) : (
        <IconItem
          type="link"
          text={email}
          icon={darkMode ? '' : 'icon-email-mc'}
          url={`mailto:${email}`}
          small={smallIcon}
          darkMode={darkMode}
          parentId="contact-email"
        />
      );
    case 'namePlate':
      return (
        <IconItem
          text={contactName}
          icon={darkMode ? '' : 'icon-instructor-mc'}
          small={smallIcon}
          darkMode={darkMode}
          parentId="contact-name-plate"
        />
      );
    default:
      return null;
  }
}

Contact.propTypes = {
  variant: PropTypes.string.isRequired,
  phone: PropTypes.string,
  text: PropTypes.string,
  email: PropTypes.string,
  contactName: PropTypes.string,
  longForm: PropTypes.bool,
  smallIcon: PropTypes.bool,
  darkMode: PropTypes.bool,
};
