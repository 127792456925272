import { styled } from '@mui/material/styles';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from '@mui/material';

export const Card = styled(MuiCard, {
  shouldForwardProp: (prop) => prop[0] !== '$',
})(({
  theme, $alumniTile, $assignedContacts, $applyMarginBottom, $fullWidth,
}) => ({
  color: theme.palette.text.primary,
  padding: $alumniTile ? theme.spacing(2, 2, 3.3, 2) : theme.spacing(2, 2, 4, 2),
  boxShadow: '0 0.1rem 0.2rem rgba(0,0,0,0.2)',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  minHeight: theme.spacing(24.5),
  marginTop: $assignedContacts ? theme.spacing(1.6) : 0,
  marginBottom: $applyMarginBottom ? theme.spacing(2.6) : 0,
  [theme.breakpoints.up('md')]: {
    padding: $alumniTile ? theme.spacing(2, 2, 3.3, 2) : theme.spacing(2, 3.5, 3.5, 2),
    ...($fullWidth && {
      minHeight: theme.spacing(27.5),
    }),
    ...($assignedContacts && {
      minHeight: theme.spacing(22),
    }),
    ...($alumniTile && {
      minHeight: theme.spacing(37.1),
    }),
  },
}));

export const CardContent = styled(MuiCardContent, {
  shouldForwardProp: (prop) => prop[0] !== '$',
})(({ theme, $applyMarginLeftToCardContent }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0',
  padding: theme.spacing(0),
  marginLeft: $applyMarginLeftToCardContent ? theme.spacing(1.4) : 0,
  '&:last-child': {
    padding: 0,
  },
}));

export const Heading = styled(MuiTypography, {
  shouldForwardProp: (prop) => prop[0] !== '$',
})(({ theme, $alumniTile }) => ({
  textTransform: 'uppercase',
  fontWeight: 600,
  color: theme.palette.text.disabled,
  marginBottom: theme.spacing(1),
  lineHeight: theme.spacing(1.5),
  [theme.breakpoints.up('md')]: {
    marginBottom: $alumniTile ? theme.spacing(0.9) : theme.spacing(2),
  },
}));
