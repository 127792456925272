import { EmailModel } from '@/types/api/contacts';
import { PersonName } from '@/types/api/persons';
import { Membership } from '@/types/api/learning';

export const getUniversityEmailObjectByPersonId = (
  personId: string,
  emailObjects: EmailModel[],
) : EmailModel | {} => {
  const emailsByPersonId = emailObjects.filter(
    (email) => email?.linkType?.toLowerCase() === 'person' && email?.linkId === personId,
  );
  const universityEmails = emailsByPersonId?.filter(
    (emails) => emails?.type?.toLowerCase() === 'university',
  );
  const primaryUniversityEmails = universityEmails?.filter(
    (email) => email?.primary?.toLowerCase() === 'true',
  );
  const universityEmail = primaryUniversityEmails[0] || universityEmails[0];

  // existing components use key name email vs emailAddress
  const transformedUniversityEmail = {
    ...universityEmail,
    email: universityEmail?.emailAddress,
  };

  // delete to reduce redundancy and confusion
  delete transformedUniversityEmail.emailAddress;

  return universityEmail ? transformedUniversityEmail : {};
};

export const getNameObjectByPersonId = (
  personId: string,
  namesObject: PersonName[],
) : PersonName | {} => {
  const namesByPersonId = namesObject.filter(
    (name) => name?.personId === personId && name?.type?.toLowerCase() === 'official',
  );
  return namesByPersonId[0] || {};
};

export const createUniversityContactObject = (
  membership: Membership,
  emailObjects: EmailModel[],
  namesObject: PersonName[],
) => {
  const { personId } = membership;
  if (!personId
      || !namesObject
      || namesObject.length < 1
  ) {
    return null;
  }

  // existing components use key names membershipId and sectionId vs id and sourceId
  const transformedMembership = {
    ...membership,
    membershipId: membership.id,
    sectionId: membership.sourceId,
  };

  // delete to reduce redundancy and confusion
  delete transformedMembership?.id;
  delete transformedMembership?.sourceId;

  return {
    ...transformedMembership,
    ...getUniversityEmailObjectByPersonId(personId, emailObjects),
    ...getNameObjectByPersonId(personId, namesObject),
  };
};
